import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 75px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1600px) {
    text-align: -webkit-center;
    max-width: 100%;
  }

  .makeStyles-paper-1 {
    width: 20%;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -15px) scale(0.75);
  }

  .PrivateNotchedOutline-legendLabelled-7 > span {
    display: none;
  }

  .MuiInputBase-root:hover {
    fieldset {
      border: 2px solid rgb(80, 216, 114);
    }
  }

  .MuiOutlinedInput-root,
  .MuiFormControl-fullWidth {
    margin: 10px 0px;
  }

  .MuiOutlinedInput-root {
    border-radius: 5px;
    color: rgb(83, 91, 89);
    font-size: 14px;
    transition: border 0.3s ease 0s;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid rgb(80, 216, 114);
  }

  .MuiFormLabel-root.Mui-focused {
    color: rgb(80, 216, 114);
  }

  #submit-btn {
    border-radius: 25px;
    height: 35px;
  }

  #submit-btn:hover {
    background: rgba(34, 114, 53, 0.94);
    border: rgba(34, 114, 53, 0.94);
  }

  .MuiButton-label {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: bold;
  }
`

export default Container
