/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Login from './pages/Login'
import Clients from './pages/Clients'
import { isAuthenticated } from './services/auth'

const PrivateRoute = ({ Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
)

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <PrivateRoute path="/clients" component={Clients} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
