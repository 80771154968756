import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
html, body {
  width: 100%;
  min-height: 100%;
}

body {
  background-color: #fff;
  -webkit-font-smoothing: antialiased !important;
  font-family: 'Lato', sans-serif !important;
}

body, input, button {
  font-size: 16px;
}

input {
  border: none;
}

.grecaptcha-badge { visibility: hidden; }

::selection{
    background: #4f8afa;
    color: #fff
  }

`
