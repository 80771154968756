/* eslint-disable max-len */
/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import InputMask from 'react-input-mask'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import Container from './styles'
import api from '../../services/api'
import edit from '../assets/edit.png'
import Toast from '../../components/Toast'
import { isAuthenticated } from '../../services/auth'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    padding: '8% 0 0',
  },
}

const statusArray = [
  'Aguardando Disponibilidade',
  'Aguardando Agendamento',
  'Ativação Agendada',
  'Ativação Efetuada',
]

export default function Clients() {
  const history = useHistory()

  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [clients, setClients] = useState([])
  const [plans, updatePlans] = useState([])
  const [selectedId, setSelectedId] = useState(0)

  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [document, setDocument] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [status, setStatus] = useState('Aguardando Disponibilidade')
  const [planId, setPlanId] = useState(0)
  const [coordinates, updateCoordinates] = useState([0, 0])
  const [startDate, setStartDate] = useState(new Date())

  const [mask, setMask] = useState('999.999.999-999')
  const [maskPhone, setMaskPhone] = useState('(99) 9999-99999')

  const GOOGLE_MAPS_API_URL =
    'https://maps.googleapis.com/maps/api/geocode/json'

  useEffect(() => {
    if (!isAuthenticated()) history.push('/')
  }, [history])

  const getClients = () => {
    api
      .get('/clients')
      .then((response) => {
        setClients(response.data)
      })
      .catch((response) =>
        toast.error('Houve um problema ao tentar carregar a lista de clientes')
      )
  }

  useEffect(() => {
    getClients()
  }, [])

  useEffect(() => {
    api
      .get('/plans')
      .then((response) => {
        updatePlans(response.data)
      })
      .catch((response) =>
        toast.error('Houve um problema ao tentar carregar a lista de clientes')
      )
  }, [selectedId])

  useEffect(() => {
    if (selectedId === 0) return

    const handleGetClient = async () => {
      try {
        const response = await api.get(`clients/${selectedId}`)

        if (response.status !== 200) return

        const { data } = response

        setName(data.name)
        setLastName(data.lastName)
        setDocument(data.document)
        setPhone(data.phone)
        setEmail(data.email)
        setStatus(data.status)
        setAddress(data.address)
        setPlanId(data.planId)
      } catch (error) {
        if (error.response?.status === 400 || error.response?.status === 401) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Houve um problema ao realizar o login')
        }
      }
    }

    handleGetClient()
  }, [selectedId])

  function toggleModal() {
    setIsOpen(!modalIsOpen)
  }

  function handleGetCoordinates() {
    if (address.length > 0) {
      axios
        .get(
          `${GOOGLE_MAPS_API_URL}?address=${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        )
        .then((response) => {
          const { results } = response.data

          if (results.length === 0) return

          setAddress(results[0].formatted_address)
          updateCoordinates([
            results[0].geometry.location.lat,
            results[0].geometry.location.lng,
          ])
        })
    }
  }

  async function handleRegisterClientData(event) {
    event.preventDefault()

    try {
      const response = await api.post('clients', {
        client: {
          name,
          lastName,
          document,
          email,
          phone,
        },
        address,
        latitude: coordinates[0],
        longitude: coordinates[1],
        planId,
      })

      if (response.status === 201) {
        toast.success('Cliente cadastrado com sucesso!')
        getClients()
        toggleModal()
      }
    } catch (error) {
      if (error.response?.status === 400 || error.response?.status === 401) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Houve um problema ao realizar o login')
      }
    }
  }

  async function handleUpdateClientData(event) {
    event.preventDefault()

    try {
      const response = await api.put(`clients/${selectedId}`, {
        client: {
          name,
          lastName,
          document,
          email,
          phone,
        },
        address,
        latitude: coordinates[0],
        longitude: coordinates[1],
        planId,
      })

      if (response.status === 201) {
        toast.success('Dados atualizados com sucesso!')
        getClients()
        toggleModal()
      }
    } catch (error) {
      if (error.response?.status === 400 || error.response?.status === 401) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Houve um problema ao realizar o login')
      }
    }
  }

  const handleValidadeCPFAndCNPJ = (event) => {
    const { value } = event.target
    if (value.length > 14) {
      setMask('99.999.999/9999-99')
    } else {
      setMask('999.999.999-999')
    }
    setDocument(value)
  }

  const handleChangeDocument = (event) => {
    setDocument(event.target.value)
    handleValidadeCPFAndCNPJ(event)
  }

  const handleValidadePhone = (event) => {
    const { value } = event.target
    if (value.length > 14) {
      setMaskPhone('(99) 99999-9999')
    } else {
      setMaskPhone('(99) 9999-99999')
    }
    setPhone(value)
  }

  const handleChangePhone = (event) => {
    setPhone(event.target.value)

    handleValidadePhone(event)
  }

  return (
    <Container>
      <Toast />
      <div className="cad">
        <div className="submit-btn">
          <button
            type="submit"
            className="modal-btn"
            onClick={() => {
              toggleModal()
              setSelectedId(0)
            }}
          >
            Realizar Cadastro
          </button>
        </div>
        <div className="table">
          <table className="cad-table">
            <tr className="head-table">
              <th className="th-table">Editar</th>
              <th className="th-table">Nome</th>
              <th className="th-table">Endereço</th>
              <th className="th-table">Telefone</th>
              <th className="th-table">Status</th>
            </tr>
            {clients?.map((client) => (
              <tr className="data-table" key={client.id}>
                <td>
                  <button
                    type="submit"
                    className="btn"
                    onClick={() => {
                      setSelectedId(client.id)
                      toggleModal()
                    }}
                  >
                    <img src={edit} alt="Editar" />
                  </button>
                </td>
                <td className="td-table">{client.fullName}</td>
                <td className="td-table">{client.address}</td>
                <td className="td-table">{client.phone}</td>
                <td className="td-table">{client.status}</td>
              </tr>
            ))}
          </table>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Container>
            <form
              className="form"
              onSubmit={
                selectedId === 0
                  ? handleRegisterClientData
                  : handleUpdateClientData
              }
            >
              <div className="close-form">
                <span
                  className="close"
                  onClick={toggleModal}
                  role="presentation"
                >
                  X
                </span>
              </div>
              <div className="input-group">
                <div className="input-block">
                  <label htmlFor="name">Nome</label>
                  <input
                    className="form-input"
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    required
                  />
                </div>

                <div className="input-block">
                  <label htmlFor="name">Nome</label>
                  <input
                    className="form-input"
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="input-group">
                <div className="input-block">
                  <label htmlFor="document">CPF/CNPJ</label>
                  <InputMask
                    mask={mask}
                    maskChar=""
                    value={document}
                    onChange={handleChangeDocument}
                    className="form-input"
                    type="text"
                    name="document"
                    id="document"
                    required
                  />
                </div>

                <div className="input-block">
                  <label htmlFor="phone">Telefone</label>
                  <InputMask
                    mask={maskPhone}
                    maskChar=""
                    value={phone}
                    onChange={handleChangePhone}
                    className="form-input"
                    type="text"
                    name="phone"
                    id="phone"
                    required
                  />
                </div>
              </div>

              <div className="input-group">
                <div className="input-block">
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    name="status"
                    required
                    value={status}
                    onChange={(event) => setStatus(event.target.value)}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    {statusArray?.map((sa) => (
                      <option key={sa} value={sa} selected={sa === status}>
                        {sa}
                      </option>
                    ))}
                  </select>
                  {status === 'Ativação Agendada' ? (
                    <div className="calendar">
                      <DatePicker
                        className="calendar-input"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="dd/MM/yyyy HH:mm "
                      />
                    </div>
                  ) : null}
                </div>
                <div className="input-block">
                  <label htmlFor="address">Endereço</label>
                  <input
                    className="form-input"
                    type="text"
                    name="address"
                    id="address"
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                    onBlur={handleGetCoordinates}
                    required
                  />
                </div>
              </div>

              <div className="input-group">
                <div className="input-block">
                  <label htmlFor="email">Email</label>
                  <input
                    className="form-input"
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                  />
                </div>
                <div className="input-block">
                  <label htmlFor="planId">Velocidade da Internet</label>
                  <select
                    id="planId"
                    name="planId"
                    required
                    value={planId}
                    onChange={(event) => setPlanId(event.target.value)}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    {plans?.map((plan) => (
                      <option
                        key={plan.id}
                        value={plan.id}
                        selected={planId === plan.id}
                      >
                        {plan.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* <div className="input-group">
                <div className="radio-block">
                  <label htmlFor="ip">IP Fixo</label>
                  <div className="radio-form" required>
                    <input type="radio" name="ip" value="sim" />
                    <label className="radio-label" htmlFor="sim">
                      Sim
                    </label>
                    <input type="radio" name="ip" value="nao" />
                    <label className="radio-label" htmlFor="nao">
                      Não
                    </label>
                  </div>
                </div>
                <div className="radio-block">
                  <label htmlFor="l2l">Lan 2 Lan</label>
                  <div className="radio-form">
                    <input type="radio" name="l2l" value="sim" />
                    <label className="radio-label" htmlFor="sim">
                      Sim
                    </label>
                    <input type="radio" name="l2l" value="nao" />
                    <label className="radio-label" htmlFor="nao">
                      Não
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="btn-form">
                <button className="button" type="submit">
                  Cadastrar Cliente
                </button>
              </div>
            </form>
          </Container>
        </Modal>
      </div>
    </Container>
  )
}
