import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    .form {
      height: auto !important;
    }

    .input-group {
      flex-direction: column !important;
    }

    .input-block {
      width: 100% !important;
    }

    .radio-block {
      margin-top: 15px !important;
    }

    .modal-btn {
      width: 100% !important;
    }

    .head-table,
    .data-table {
      display: grid !important;
      grid-template-columns: repeat(4, 270px) !important;
      overflow-x: auto !important;
    }

    .cad-table {
      padding: 15px 0 !important;
    }

    .td-table {
      font-size: 13px !important;
      width: 270px !important;
      align-self: center !important;
    }
  }

  .cad {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }

  .table {
    width: 100%;
    height: auto;
    overflow-x: auto;
  }

  .cad-table {
    width: 100%;
    padding: 15px;
    display: table;
  }

  .head-table {
    border: 2px solid rgb(34, 114, 53);
    background: rgb(34, 114, 53);
    color: #fff;
  }

  .data-table {
    background-color: rgba(34, 114, 53, 0.1);
    color: rgb(83, 91, 89);
    text-align-last: center;
    height: auto;
    font-size: 15px;
  }

  .td-table {
    width: 25%;
    text-align: center;
  }

  #status-table {
    background-color: transparent;
    border: none;
    width: 100%;
    cursor: pointer;
  }

  .modal-btn {
    width: 50%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    text-align: center;
    border-radius: 25px;
    color: #fff;
    text-transform: uppercase;
    height: 30px;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    background: rgb(34, 114, 53);
    border: 2px solid rgb(34, 114, 53);
    cursor: pointer;
    outline: none;
  }

  .modal-btn:hover {
    background: #227235f0;
    border: 2px solid #227235f0;
  }

  span {
    font-size: 17px;
  }

  .form {
    max-width: 550px;
    width: 100%;
    margin-bottom: 35px;
    background-color: rgba(34, 114, 53, 0.1);
    border-radius: 8px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
  }

  .input-group {
    display: flex;
    justify-content: space-between;
  }

  .input-block {
    flex-direction: column;
    display: flex;
    width: 45%;
    justify-content: space-between;
    height: fit-content;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .calendar-input {
    border: 1px solid rgb(255, 255, 255);
    background: rgb(255, 255, 255);
    border-radius: 5px;
    height: 35px;
    padding: 0px 10px;
    color: rgb(83, 91, 89);
    font-size: 14px;
    transition: border 0.3s ease 0s;
    width: 91%;
    cursor: pointer;
  }

  label {
    align-self: start;
    color: #535b59;
    font-size: 14px;
    margin: 10px 0;
    font-weight: bold;
  }

  .form-input,
  select {
    border: 1px solid rgb(255, 255, 255);
    background: rgb(255, 255, 255);
    border-radius: 5px;
    height: 35px;
    padding: 0px 10px;
    color: rgb(83, 91, 89);
    font-size: 14px;
    transition: border 0.3s ease 0s;
  }

  .radio-block {
    width: 45%;
    text-align: left;
  }

  .radio-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .radio-label {
    width: 35%;
  }

  .calendar {
    margin-top: 20px;
  }

  .submit-btn {
    width: 100%;
    display: flex;
    place-content: center;
  }

  .btn-form {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    text-align: center;
  }

  .button {
    width: 100%;
    height: 35px;
    border-radius: 35px;
    background: #227235;
    border: 2px solid #227235;
    color: #fff;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    transition: all 0.3s;
  }

  .button:hover {
    background: #227235f0;
    border: 2px solid #227235f0;
  }

  .close-form {
    width: 20px;
    align-self: flex-end;
  }

  .close {
    cursor: pointer;
  }

  .btn {
    border: none;
    cursor: pointer;
    outline: none;
  }

  img {
    width: 1rem;
  }
`

export default Container
